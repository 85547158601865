:root {
  --header-height: 300px; /* Adjust this value as needed */
  --light-border-color: #e2e8f0; /* slate-800 */
  --dark-border-color: #1e293b; /* slate-800 */

}

.calendar-container {
    font-family: 'Inter', sans-serif; /* Add fallback font */
  }



  
  /* Outside border for caledar dates*/
  .fc-daygrid-body {
    border: 0px solid #28a745; /* Left border of the entire day grid */
  }

  /* Event borders */
    .fc-daygrid-event {
    border: 0px solid #0cfffb !important; /* Border of individual events */
  }



  /* Background color for current day in Month view */
    .light .fc-dayGridMonth-view .fc-day-today {
    background-color: #f1f5f9 !important;
    opacity: 1
  }
    .dark .fc-dayGridMonth-view .fc-day-today {
    background-color: #374151 !important;
    opacity: .8
  }

  /* Background color for current day in Month view */
    .light .fc-timegrid-cols  .fc-day-today {
      background-color: rgba(0, 0, 0, .05) !important;
        /* background-color: rgba(203, 213, 225, 0.1) !important; */
    }
    .light .fc-daygrid-body .fc-day-today {
        background-color: rgba(0, 0, 0, .07) !important;
        /* background-color: rgba(203, 213, 225, 0.2) !important; */
    }
    .dark .fc-timegrid-cols  .fc-day-today {
        background-color: rgba(51, 65, 85, 0.3) !important;
    }
    .dark .fc-daygrid-body .fc-day-today {
        background-color: rgba(51, 65, 85, 0.3) !important;
    }

    /* Background color for current day in DAY view, removing any special highlighting since I already have the red line */
    .light .fc-timeGridDay-view .fc-day-today{
        background-color: #ffffff !important;
    }
    .dark .fc-timeGridDay-view .fc-day-today{
        background-color: #020617 !important;
    }

    /* this is for the time shown in the left side of the day grid */
    .fc .fc-timegrid-slot-label-cushion {
        text-transform: uppercase; /* Capitalize AM/PM */
        font-size: 12px;
        letter-spacing: 0.05em;
    }


  

    .dark .fc-col-header  {
        background-color: #0f172a !important;
        /* background-color: #020617 !important; */
        /* border-bottom: 0px solid var(--dark-border-color) !important; */
    }
  


  .fc-dayGridMonth-view  {
    border: 0px solid #0cfffb !important;
  }

  .fc-view {
    border: 0px solid #0cfffb !important;
  }
  .fc-daygrid  {
    border: 0px solid #0cfffb !important;
  }

  .fc-theme-standard td, .fc-theme-standard th {
    border: 0px solid #0cfffb 
  }



  .dark .fc {
    border: 0px solid #020617; /* Overall calendar border */
  }
  .light .fc {
    border: 0px solid #f1f5f9; /* Overall calendar border */
  }

  
  
  .fc .fc-daygrid-day {
    height: calc((100vh - var(--header-height)) / 6) !important;
  }
  

  


 
  .light .fc-toolbar-title {
    color: #333333; /* Header text color */
    font-size: 20px;
    font-weight: bold;
  }
  

  



  .fc-col-header-cell-cushion {
    font-weight: 400;
    border: 0px solid #0cfffb; 
    font-size: 18px;
}

  /* Monthly view header (your existing style) */
    .fc-dayGridMonth-view .fc-col-header-cell-cushion {
        display: flex;
        justify-content: flex-end;
        border: 0px solid #0cfffb; 
    }

  
    
    .fc-event {
        /* height: 100%;  */
        border:0px solid #28a745
        /* align-items: center; */
      }
      .fc-timegrid-col-events .fc-event {
        height: 100%; /* Ensure the event takes full height */
        width: 100%; 
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border:0px solid #28a745
        /* align-items: center; */
      }
      
      .fc-event-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .fc-timegrid-event-harness-inset .fc-timegrid-event {
        box-shadow: 0 0 0 0px #ffffff;
      }
      .fc-v-event {
        background-color: transparent;
      }
      
      /* CSS for the popover that comes up when there is an overflow in the amount of events */
      .light .fc-theme-standard .fc-popover {
        background-color: #f8fafc;
        border: 0px solid #ffff;
        border-radius: 0.5rem;
      }
      .dark .fc-theme-standard .fc-popover-header  {
        background-color: #cbd5e1;
        border: 0px solid #ffff;
        border-top-left-radius: 0.5rem; /* 8px */
        border-top-right-radius: 0.5rem; /* 8px */
      }
      .dark .fc-theme-standard .fc-popover {
        background-color: #1e293b;
        border: 0px solid #ffff;
        border-radius: 0.5rem;
      }
      .dark .fc-theme-standard .fc-popover-header  {
        background-color: #334155;
        border: 0px solid #ffff;
        border-top-left-radius: 0.5rem; /* 8px */
        border-top-right-radius: 0.5rem; /* 8px */
      }



      /* .fc-timegrid-now-indicator-line {
        background-color: #fee2e2 !important; 
      }
      
      .fc-timegrid-now-indicator-arrow {
        color: #fee2e2 !important; 
      } */


  /* Bottom and internal borders for the header */
  .light .fc-col-header-cell {
    background-color: #ffffff; /* slate-800 */ 
    color: #4b5563;  /* slate-300 */ 
    /* border: 5px solid #0cfffb;  */
    border: 0px solid transparent; 
  }
  .dark .fc-col-header-cell {
    background-color: #0f172a; /* slate-900 */ 
    color: #cbd5e1;  /* slate-300 */ 
    /* border: 5px solid #0cfffb;  */
    border: 0px solid transparent; 
  }
  




  /* Border colors for inside lines in Month Calender */
  .light .fc .fc-daygrid-day {
    border: 1px solid var(--light-border-color); /* Custom border color */
  }
  .dark .fc .fc-daygrid-day {
    border: 1px solid var(--dark-border-color); /* Custom border color */
  }


    /* In week/day, it is the lines between hours */
  .light .fc .fc-timegrid-slot {
    border: 1px solid var(--light-border-color); /* Custom border color */
  }
  .dark .fc .fc-timegrid-slot {
    border: 1px solid var(--dark-border-color); /* Custom border color */
  }
  .dark .fc .fc-timegrid-col {
    border-right: 1px solid var(--dark-border-color); /* Custom border color */
    border-left: 1px solid var(--dark-border-color); /* Custom border color */
  }



  
  .dark .fc-toolbar-title {
    color: #cbd5e1; /* Main title of the whole calendar giving overview date */
    font-size: 20px;
    font-weight: bold;
  }

   .fc-toolbar {
    margin-top:12px;
    margin-right: 12px;
    margin-left: 12px;
  }

  .fc-h-event {
    background-color: transparent;
  }

  /* Remove background color on event hover */
  .fc-daygrid-event:hover {
    background: none;
    box-shadow: none;
  }
  
 
  .fc-button {
    text-transform: capitalize !important;
    border-radius:8px !important;
    border:1px solid transparent !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3) !important;
    font-size: 14px !important;
  }
  .light .fc-button {
    background-color: #ffffff;
    color: #475569;
  } 
  .light .fc-button:hover {
    background-color: #f1f5f9 !important;
    color: #475569 !important;
  }
  .light .fc-button-primary.fc-button-active {
    background-color: #f1f5f9 !important;
    color: #475569 !important;
  }

  .dark .fc-button {
    background-color: #475569;
    color: #ffffff;
  }
  .dark .fc-button:hover {
    background-color: rgba(71, 85, 105, 0.8) !important;
  }
  .dark .fc-button-primary.fc-button-active {
    background-color: rgba(71, 85, 105, 0.5) !important;
    color: #ffffff !important;
  }

  .fc .fc-button .fc-icon {
    font-size: 1.5em;
  }





  .fc {
    border: 0px solid #0cfffb !important;
  }
  
  .fc-scrollgrid {
    border: 0px solid #0cfffb !important;
  }
  
  .fc-scrollgrid-sync-table {
    border: 0px solid #0cfffb !important;
  }
  
  .fc-view-harness {
    border: 0px solid #0cfffb !important;
  }
  
  .fc-daygrid-body {
    border: 0px solid #0cfffb !important;
  }




.fc-daygrid-day-frame {
    border-radius: 100px;
}
  
    