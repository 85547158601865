@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
      font-family: 'Inter';
    }
    body {
      margin:0;
      padding:0;
      overflow-y: hidden;
  
    }
    body:hover {
        overflow-y: scroll;
    }
    
    
    @layer base {
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button,
      input[type='number'] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
      }
   }
   
  }


@layer components {
    
    .string {
      @apply text-green-300
    }
    .number {
      @apply text-rose-300
    }
    .boolean {
      @apply text-blue-400
    }
    .null {
      @apply text-purple-300
    }
    .key {
      @apply text-violet-300
    }
    .navBtn {
        @apply hidden h-6 md:inline-flex cursor-pointer hover:scale-110 transition-all duration-150 ease-out text-gray-700 dark:text-gray-100 outline-none focus:outline-none
      }
      .navBtnMobile {
          @apply inline-flex h-6 md:hidden cursor-pointer hover:scale-110 transition-all duration-150 ease-out text-gray-700 dark:text-gray-100 outline-none
    }
    .navBtnMobile {
        @apply inline-flex h-6 md:hidden cursor-pointer hover:scale-110 transition-all duration-150 ease-out text-gray-700 dark:text-gray-100 outline-none
    }
    
    .executeIcon {
      @apply h-7 w-7 text-blue-600 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition ease-out delay-100 hover:-translate-y-0.5
    }

    .btnMain {
      @apply flex text-white items-center bg-blue-600 hover:bg-blue-700 rounded-full py-1 pl-1 pr-3 w-max 
    }
    .btnMainIcon {
      @apply  text-white h-5 w-5 
    }
    .btnMainText {
      @apply  text-white text-sm 
    }
    .breadcrumbsNav {
      @apply cursor-pointer text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-500
    }
    .breadcrumbsSpacer {
      @apply mx-2 text-gray-500 dark:text-gray-400
    }
    .breadcrumbsCurrent {
      @apply text-gray-500 dark:text-gray-400 truncate max-w-[150px]
    }
    .workorderUnderline {
      @apply bg-blue-600 absolute bottom-0 left-0 right-0 h-[2px] rounded-t-full
    }
    .underline {
      @apply bg-blue-600 absolute bottom-0 left-0 right-0 h-1 rounded-t-full
    }
    .mainNavUnderline {
      @apply bg-blue-500 absolute bottom-0 left-0 right-0 h-[2px] rounded-t-full
    }
    .mainUnderline {
      @apply absolute left-0 top-0 z-0 font-medium outline-none text-sm text-white cursor-pointer px-2 py-0.5 bg-blue-600 rounded-full h-full w-full
    }
    .mainVerticalUnderline {
      @apply  font-semibold outline-none text-sm text-white cursor-pointer p-2 bg-blue-600 dark:bg-blue-600 rounded-xl h-full 
    }
    /* .mainVerticalUnderline {
      @apply absolute left-0 top-0 z-0 font-semibold outline-none text-sm text-white cursor-pointer p-2 bg-blue-600 dark:bg-blue-600 rounded-xl h-full 
    } */
    .checkboxBtn {
      @apply w-4 h-4 text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow
    }
    .tableHeaderContainer {
      @apply text-sm text-gray-800 dark:text-gray-400 font-bold py-2 mb-2 bg-slate-200 dark:bg-slate-800
    }
    .tableHeaderRow {
      @apply text-left
    }
    .tableHeaderCell {
      @apply py-2 first:rounded-tl-2xl first:rounded-bl last:rounded-tr-2xl last:rounded-br first:pl-4
    }
    .tableBodyContainer {
      @apply text-sm text-gray-600 dark:text-gray-400 font-semibold
    }
    .tableBodyRow {
      @apply text-left border-b-[0.01em] dark:border-slate-700
    }
    .tableBodyCell {
      @apply py-1.5 first:pl-4
    }
    .invoiceTableRow {
      @apply border-b-[0.01em] dark:border-slate-700
    }
    .invoiceTableCellHeader {
      @apply text-xs font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3
    }
    .invoiceTableCellBody {
      @apply text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3
    }
}