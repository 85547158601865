.pinned-messages-container {
  width: 100%;
  height: 40px; /* Adjust based on your message height */
  overflow: hidden;
  position: relative;
}

.pinned-messages {
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-out;
}

.pinned-message {
  height: 40px; /* Should match the container height */
  display: flex;
  align-items: center;
  padding: 0 10px;
  /* background-color: #f0f0f0; */
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
}

.pinned-message.active {
  z-index: 2;
}

.pinned-message.animating {
  transform: translateY(100%);
}

.highlight-indicator {
  width: 4px;
  height: 100%;
  /* background-color: #a0d8ff; */
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.pinned-message.active .highlight-indicator {
  opacity: 1;
}

.pinned-message-content {
  margin-left: 10px;
}