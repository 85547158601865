
.MuiPickerStaticWrapper-content {
  background-color: transparent !important;
}
.css-xelq0e-MuiPickerStaticWrapper-content {
  background-color: transparent !important;
}
.css-l0iinn {
  color:#818794 !important;
}
.css-7kykdr-MuiButtonBase-root-MuiIconButton-root {
  color:#818794 !important;
}
.css-x1wgng-MuiYearPicker-root {
  color:#818794 !important;
}
.MuiSvgIcon-root  {
  color:#818794 !important;
}
.MuiTypography-root {
  color:#818794 !important;
}
.MuiPickersDay-root{
  background-color:transparent !important;
}
.MuiButtonBase-root{
  color:#818794 !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled{
  opacity: 0.4 !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid rgba(148, 163, 184, 0.4) !important;
}
.MuiDialogActions-root {
  display:none !important;
}