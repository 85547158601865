
.message-content {
  word-break: break-word; /* Ensures the text breaks within the word */
  overflow-wrap: break-word; /* Provides additional wrapping behavior */
  white-space: normal; 
}
.message-content-web {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.message-content-mobile {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

/* Target only <ol> and <ul> within .message-content */
.message-content ol,
.message-content ul {
  list-style-position: inside; /* Ensures the list markers are inside the content box */
  list-style-type: initial; /* Use default list styling: numbers for <ol>, bullets for <ul> */
  margin: 0 0 0.5em 0.5em; /* Add margin only to lists */
  padding: 0; /* Reset padding for consistent spacing */
}

/* Optional: Further style <li> elements if needed */
.message-content li {
  margin-bottom: 0em; /* Add some space between list items */
}

/* Ensure normal text (e.g., <p>, plain text) remains unaffected */
.message-content p {
  margin: 0; /* Reset margin for paragraphs if needed */
}
.message-content a {
  text-decoration: underline;
}


.mobile-message-functions{
  user-select: none;          /* Standard */
  -webkit-user-select: none;  /* Chrome, Safari */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  -webkit-touch-callout: none; /* Disable callout (e.g., copy/paste menu) */
  touch-action: manipulation;   /* Disable default touch behaviors */
}

