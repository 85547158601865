.message_window_scrollbar {
    scrollbar-width: thin;
}
.message_window_scrollbar.scrollbar_light {
    scrollbar-color: #475569 transparent;
}
.message_window_scrollbar.scrollbar_dark {
    scrollbar-color: #D3D3D3 transparent;
}
  
.message_window_scrollbar::-webkit-scrollbar {
width: 6px;
}

.message_window_scrollbar::-webkit-scrollbar-track {
background: transparent;
}

.message_window_scrollbar::-webkit-scrollbar-thumb {
background-color: #D3D3D3;
border-radius: 3px;
}