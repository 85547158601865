/* Custom styles for ReactQuill Editor */
.custom-quill .ql-container {
    border: 0px solid #007bff; /* Custom border color */
    border-radius: 8px; /* Rounded corners */
    padding: 0px; /* Padding inside the container */
    font-size: 14px; /* Custom font size */
    font-weight: 400; /* Custom font size */
    background-color: transparent; /* Custom background color */
  }
  
  /* Editable area */
  .custom-quill .ql-editor {
    min-height: 26px; 
    max-height: 180px; 
    overflow-y: auto; /* Scroll if content exceeds height */
    padding: 0px; /* Padding for text area */
    padding-left: 15px;
    line-height: 1.5; /* Line height for better readability */
    letter-spacing: 0.025em;
  }
  
  /* Placeholder styling */
  .custom-quill .ql-editor.ql-blank::before {
    color: #999; /* Custom placeholder color */
    font-style: normal; 
    letter-spacing: 0.025em;
  }
  
  /* Scrollbar styling for editable area */
  .custom-quill .ql-editor::-webkit-scrollbar {
    width: 4px; 
  }
  
  .custom-quill .ql-editor::-webkit-scrollbar-thumb {
    background-color: #94a3b8; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded scrollbar */
  }
  
  .custom-quill .ql-editor::-webkit-scrollbar-track {
    background-color: transparent; /* Scrollbar track color */
  }


/* Custom styles for ReactQuill Editor */
.mobile-custom-quill .ql-container {
    border: 0px solid #1e293b; /* Custom border color */
    border-radius: 20px; /* Rounded corners */
    padding: 0px; /* Padding inside the container */
    font-size: 16px; /* Custom font size */
    font-weight: 400; /* Custom font size */
    background-color: transparent; 
    /* background-color: #f87171; 
    background: #f87171;  */
  }

  /* Light mode styles */
.mobile-custom-quill.light .ql-container {
  background-color: #f1f5f9;
}

/* Dark mode styles */
.mobile-custom-quill.dark .ql-container {
  background-color: #1e293b;
}


  
  /* Editable area */
  .mobile-custom-quill .ql-editor {
    min-height: 26px; 
    max-height: 180px; 
    overflow-y: auto; /* Scroll if content exceeds height */
    padding-right: 4px; /* Padding for text area */
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    line-height: 1.5; /* Line height for better readability */
    letter-spacing: 0.025em;

  }
  
  /* Placeholder styling */
  .mobile-custom-quill .ql-editor.ql-blank::before {
    color: #999; /* Custom placeholder color */
    font-style: normal; 
    letter-spacing: 0.025em;
  }
  
  /* Scrollbar styling for editable area */
  .mobile-custom-quill .ql-editor::-webkit-scrollbar {
    width: 4px; 
  }
  
  .mobile-custom-quill .ql-editor::-webkit-scrollbar-thumb {
    background-color: #94a3b8; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded scrollbar */
  }
  
  .mobile-custom-quill .ql-editor::-webkit-scrollbar-track {
    background-color: transparent; /* Scrollbar track color */
  }

