.card-large-container {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(1, minmax(375px, 1fr));
  }

.card-large-multi-op-container {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(1, minmax(450px, 1fr));
  }
  

  

/* Small devices (sm) */
@media (min-width: 640px) {
    .card-large-container {
        grid-template-columns: repeat(1, minmax(375px, 1fr));
      }
   
  }
  
  /* Medium devices (md) */
  @media (min-width: 915px) {
    .card-large-container {
        grid-template-columns: repeat(2, minmax(375px, 1fr));
      }
      .card-large-multi-op-container {
        grid-template-columns: repeat(2, minmax(450px, 1fr));
      }
  }
  
  /* Large devices (lg) */
  @media (min-width: 1024px) {
    .card-large-container {
        grid-template-columns: repeat(2, minmax(375px, 1fr));
      }
  }
  
  /* Extra large devices (xl) */
  @media (min-width: 1280px) {
    .card-large-container {
        grid-template-columns: repeat(3, minmax(375px, 1fr));
      }
  }
  @media (min-width: 1400px) {
    .card-large-multi-op-container {
      grid-template-columns: repeat(3, minmax(450px, 1fr));
    }
  }
  
  /* 2XL devices */
  @media (min-width: 1536px) {
    .card-large-container {
        grid-template-columns: repeat(3, minmax(375px, 1fr));
      }
  }
  
  /* 3XL devices */
  @media (min-width: 1752px) {
    .card-large-container {
        grid-template-columns: repeat(4, minmax(375px, 1fr));
      }
  }

  @media (min-width: 1900px) {
    .card-large-multi-op-container {
      grid-template-columns: repeat(4, minmax(450px, 1fr));
    }
  }
  
  /* 4XL devices */
  @media (min-width: 2560px) {
    .card-large-container {
        grid-template-columns: repeat(5, minmax(375px, 1fr));
      }
    .card-large-multi-op-container {
        grid-template-columns: repeat(5, minmax(450px, 1fr));
      }
  }